
import { Prop, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import DropDownComponent from '@/components/common/Dropdown.vue';

@Component({
  components: {
    Dropdown: DropDownComponent,
  },
})

export default class TasqsListFilteringPopup extends mixins() {
  @Prop({
    type: Array,
    default() {
      return [];
    },
  }) selectedValues!: any;

  @Prop({
    type: Array,
    default() {
      return [];
    },
  }) options!: any;

   @Prop({ type: Boolean, default: false }) hideSelectAllOption!: boolean;

  @Prop({ type: String, default: 'Placeholder' }) placeholder!: string;

  @Prop({ type: String, default: '' }) title!: string;

  @Prop({ type: String, default: '' }) trackBy!: string;

  @Prop({ type: String, default: '' }) label!: string;

  localSelectedValues: any = []

  emitValuesUpdatedEvent() {
    if (JSON.stringify(this.selectedValues) !== JSON.stringify(this.localSelectedValues)) {
      this.$emit('update-selected-values', this.localSelectedValues);
    }
  }

  randomizeString(input) {
      // Convert the string to an array of characters
      const arr = input.split('');

      // Fisher-Yates (aka Durstenfeld) array shuffle algorithm
      for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]]; // Swap elements
      }

      // Convert the array of characters back to a string
      return arr.join('');
    }


  updatedOptions: any = []

  created() {
    if (this.label && this.title !== 'Job Types') {
      this.updatedOptions = this.options.map((item,i) => {
        item[this.label] = `${this.title} ${i+1}`;
        return item;
      });
    }else if(this.title !== 'Job Types'){
      this.updatedOptions = this.options.map((item,i) => {
        return  `${this.title} ${i+1}`;
      });
    }else{
      this.updatedOptions = this.options
    }
  }

  handleDelete(option) {
    this.localSelectedValues = this.localSelectedValues.filter((item) => item !== option);
    this.$emit('update-selected-values', this.localSelectedValues);
  }

  @Watch('selectedValues', {
      immediate: true,
  })
  async onSelectedValuesChange(value) {
    this.localSelectedValues = value;
  }
}
