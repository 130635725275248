
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import accountModule from '@/store/modules/accountModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import scheduleModule from '@/store/modules/scheduleModule';
import workflowModule from '@/store/modules/workflowModule';
import DropdownPlaceholder from '@/components/tasqsCommon/DropdownPlaceholder.vue';
import TasqsSorting from '@/components/tasqsCommon/TasqsSorting.vue';
import { getConfigEnv, sleep } from '@/utils/helpers';
import { sort } from 'fast-sort';
import { VERTICAL_LIST_SORT_DETAULT_VALUE } from '@/lib/constants';
import userProfileModule from '@/store/modules/userProfileModule';
import assetsModule from '@/store/modules/assetsModule';
import { Debounce } from 'vue-debounce-decorator';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import gptModule from '@/store/modules/gptModule';
import { mixins } from 'vue-class-component';
import GptMixin from '@/components/gpt/GptMixin';

@Component({
  components: {
    DropdownPlaceholder,
    TasqsSorting,
  },
})
export default class VerticalListFiltersAndSort extends mixins(GptMixin) {
  @Prop({ type: Boolean, default: false }) showFiltersPopup!: boolean;
  @Prop({ type: Array }) waitingOnOptions!: any[];
  @Prop({ type: String }) filterId!: string;
  @Prop({ type: String, default: '' }) filterName!: string;
  @Prop({ type: Number, default: 0 }) totalResults!: string;
  @Prop({
    type: Object,
    required: false,
    default: () => ({
      typesOptions: [],
      selectedUsernames: [],
      selectedRoutes: [],
      selectedTeams: [],
      selectedPads: [],
      selectedAreas: [],
      selectedWaitingOn: [],
      selectedWorkTicketStatus: [],
      downtimeCodes: [],
      selectedWorkTicketCategories: [],
      selectedWorkTicketTypes: [],
      selectedWorkTicketPriorities: [],
      cycleTimeGreaterThanFilter: null,
      cycleTimeLessThanFilter: null,
      unitsGreaterThanFilter: null,
      unitsLessThanFilter: null,
      defermentPercentGreaterThanFilter: null,
      defermentPercentLessThanFilter: null,
      allAlarms: false,
      shutdownAlarms: false,
      selectedSortType: VERTICAL_LIST_SORT_DETAULT_VALUE,
    }),
  })
  filteringDetails!: any;

  working: any = {};
  saveAsCustomFilter: boolean = false;
  workingFilterId: string = '';
  workingFilterName: string = '';
  loading: boolean = false;

  get isLoadingTasqs() {
    return tasqsListModule.isLoadingTasqs;
  }

  get user() {
    return workflowModule.user;
  }

  get isCivitas() {
    return getConfigEnv('OPERATOR_LOWERCASED') == 'civitas';
  }

  get isPdc() {
    return getConfigEnv('OPERATOR_LOWERCASED') == 'pdc';
  }

  get demo() {
    return getConfigEnv('OPERATOR_LOWERCASED') == 'demo';
  }


  showingTotalResultsValue: any = '';

  get isWorkTicketView() {
    return this.tasqListLevel === 'WorkTicket';
  }

  get isPadView() {
    return this.tasqListLevel === 'Pad';
  }

  get tasqListLevel() {
    return tasqsListModule.tasqListLevel;
  }

  get workTicketJobCategories() {
    return this.workTicketCategories.map((item) => item.title).sort();
  }

  get workTicketJobTypes() {
    const jobTypes = this.workTicketCategories
      .map((item) => item.Options)
      .flat();
    if (jobTypes) {
      return [...new Set(jobTypes)].sort();
    }
    return [];
  }

  get workTicketCategories() {
    return JSON.parse(getConfigEnv('WORK_TICKET_JOB_CATEGORIES'));
  }

  get allTypes() {
    return tasqsListModule.allPredictionTypes;
  }

  get areas() {
    return scheduleModule.areas;
  }

  get isWellView() {
    return !this.isPadView && !this.isWorkTicketView;
  }

  get enableRoutes() {
    const routes = scheduleModule.enabledRoutes.map((r) => r.Route);
    routes.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    return routes;
  }

  get teams() {
    return workflowModule.teams;
  }

  get enabledPads() {
    return assetsModule.enabledPads.map((r) => r.wellName);
  }

  get availableUsers() {
    return accountModule.reassignmentList
      .filter((u) => u && u.email)
      .map((i) => ({
        // @ts-ignore
        text: i.name || 'Test User',
        value: i.email || '',
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
  }

  get textSearchValue() {
    return tasqsListModule.textSearchValue;
  }

  get isMobileView() {
    return tasqsListModule.isMobileView;
  }

  get completedTasqs() {
    return tasqsListModule.completedTasqList;
  }

  get nodeMetaChild() {
    return tasqProductionDataChartModule.nodeChildMeta;
  }

  get nodeMetaChildValues() {
    const filterLists = {
      routes: this.working.selectedRoutes,
      teams: this.working.selectedTeams,
      areas: this.working.selectedAreas,
      pads: this.working.selectedPads,
    };
    return this.getDistinctNames(this.nodeMetaChild, filterLists);
  }

  get filteringOptions() {
    return [
      {
        showCondition: this.isWorkTicketView,
        selectedValues: this.working.selectedWorkTicketStatus,
        eventKey: 'selectedWorkTicketStatus',
        options: ['Open', 'In Progress', 'Blocked', 'Complete'],
        title: 'Status',
        placeholder: 'ALL STATUS',
      },

      {
        showCondition: this.isWorkTicketView,
        selectedValues: this.working.selectedWorkTicketCategories,
        eventKey: 'selectedWorkTicketCategories',
        options: this.workTicketJobCategories,
        title: 'Categories',
        placeholder: 'ALL CATEGORIES',
      },

      {
        showCondition: this.isWorkTicketView,
        selectedValues: this.working.selectedWorkTicketTypes,
        eventKey: 'selectedWorkTicketTypes',
        options: this.workTicketJobTypes,
        title: 'Type',
        placeholder: 'ALL TYPES',
      },

      {
        showCondition: this.isWorkTicketView,
        selectedValues: this.working.selectedWorkTicketPriorities,
        eventKey: 'selectedWorkTicketPriorities',
        options: ['Highest', 'High', 'Medium', 'Low', 'Lowest'],
        title: 'Priority',
        placeholder: 'ALL PRIORITIES',
      },

      {
        showCondition: true,
        selectedValues: this.working.selectedAreas,
        eventKey: 'selectedAreas',
        options: this.nodeMetaChildValues.areas,
        title: 'Area',
        placeholder: 'ALL Areas',
      },

      {
        showCondition: true,
        selectedValues: this.working.selectedRoutes,
        eventKey: 'selectedRoutes',
        options: this.nodeMetaChildValues.routes,
        title: 'Route',
        placeholder: 'ALL ROUTES',
      },
      {
        showCondition: true,
        selectedValues: this.working.selectedTeams,
        eventKey: 'selectedTeams',
        options: this.nodeMetaChildValues.teams,
        title: 'Team',
        placeholder: 'ALL TEAMS',
      },
      {
        showCondition: !this.isPadView && !this.isWorkTicketView,
        selectedValues: this.working.selectedWaitingOn,
        eventKey: 'selectedWaitingOn',
        options: this.waitingOnOptions,
        title: 'Waiting On',
        placeholder: 'ALL WAITING ON',
      },

      {
        showCondition: true,
        selectedValues: this.working.selectedUsernames,
        eventKey: 'selectedUsernames',
        options: this.availableUsers,
        title: 'Assigned To',
        trackBy: 'value',
        label: 'text',
        placeholder: 'ALL ASSIGNEES',
      },
      {
        showCondition: !this.isWorkTicketView,
        selectedValues: this.working.typesOptions,
        eventKey: 'typesOptions',
        options: !this.isPadView
          ? [...this.allTypes, 'TO-DO FOR YOU', 'TO-DO LIST']
          : ['TO-DO FOR YOU', 'TO-DO LIST'],
        title: 'Job Types',
        placeholder: 'All JOB TYPES',
      },
      {
        showCondition: !this.isPadView,
        selectedValues: this.working.selectedPads,
        eventKey: 'selectedPads',
        options: this.nodeMetaChildValues.pads,
        title: 'Pad',
        hideSelectAllOptions: true,
        placeholder: 'All PADS',
      },
      {
        showCondition: !this.isPadView && this.operator === 'petronas',
        selectedValues: this.working.downtimeCodes,
        eventKey: 'downtimeCodes',
        options: this.downtimeCodes,
        title: 'Downtime Codes',
        hideSelectAllOptions: true,
        placeholder: 'Downtime Codes',
      },
    ];
  }

  get operator() {
    return getConfigEnv('OPERATOR_LOWERCASED');
  }

  get downtimeCodes() {
    return [...gptModule.lastestDowntimeCodes];
  }

  get activeFilter() {
    return tasqsListModule.activeFilterByPage(this.$route.name ?? '');
  }

  getTasqs(selectedRoutes = null, selectedAreas = null, selectedPads = null) {
    return tasqsListModule.tasqs(selectedRoutes, selectedAreas);
  }

  getAllTasqs(
    selectedRoutes = null,
    selectedAreas = null,
    selectedPads = null
  ) {
    let allTasqs: any = this.getTasqs(
      selectedRoutes,
      selectedAreas,
      selectedPads
    );
    if (
      !this.isMobileView &&
      this.textSearchValue.length &&
      !this.isWorkTicketView
    ) {
      allTasqs = [...allTasqs, ...this.completedTasqs];
    }
    return allTasqs;
  }

  validateNameUniqueness() {
    return this.user.customFilters.findIndex((customFilter) => {
      if (this.workingFilterId) {
        return (
          customFilter.id !== this.workingFilterId &&
          customFilter.name === this.workingFilterName &&
          customFilter.level === this.tasqListLevel
        );
      } else {
        return (
          customFilter.name === this.workingFilterName &&
          customFilter.level === this.tasqListLevel
        );
      }
    });
  }

  async validateForm() {
    const { valid } = await (
      this.$refs.customFilterNameField as any
    ).validate();
    if (!valid) {
      return false;
    }

    const index = this.validateNameUniqueness();
    if (index !== -1) {
      // @ts-ignore
      this.$refs.customFilterNameField.setErrors([
        'Filter name should be unique.',
      ]);
      return false;
    }

    return true;
  }

  async handleApply() {
    if (this.saveAsCustomFilter) {
      const formValid = await this.validateForm();
      if (!formValid) {
        return;
      }

      this.loading = true;
      const savedFilter: any = await userProfileModule.saveCustomFilter({
        filterId: this.workingFilterId,
        filterName: this.workingFilterName,
        filterLevel: this.tasqListLevel,
        pageName: this.$route.name,
        filterSettings: JSON.stringify(this.working),
      });
      this.workingFilterId = savedFilter.id;
    }
    this.setFilteringValuesInStore();
    let filteredData = this.applyFilteringOnTasqs(
      this.getAllTasqs(),
      this.tasqListLevel,
      this.activeFilter.settings
    );
    this.$emit('records-filtered', {
      results: filteredData,
      sortBy: this.working.selectedSortType,
    });
    this.$emit('show-filters-popup-updated', false);
    this.$emit('current-tasq-limit-updated', 20);

    this.loading = false;
  }

  resetAll() {
    this.working.typesOptions = [];
    this.working.selectedUsernames = [];
    this.working.selectedRoutes = [];
    this.working.selectedTeams = [];
    this.working.selectedAreas = [];
    this.working.selectedWaitingOn = [];
    this.working.selectedWorkTicketStatus = [];
    this.working.selectedWorkTicketCategories = [];
    this.working.selectedPads = [];
    this.working.selectedWorkTicketTypes = [];
    this.working.selectedWorkTicketPriorities = [];
    this.working.cycleTimeGreaterThanFilter = null;
    this.working.cycleTimeLessThanFilter = null;
    this.working.unitsGreaterThanFilter = null;
    this.working.unitsLessThanFilter = null;
    this.working.defermentPercentGreaterThanFilter = null;
    this.working.defermentPercentLessThanFilter = null;
    this.working.downtimeCodes = [];
    this.working.allAlarms = false;
    (this.working.shutdownAlarms = false), (this.saveAsCustomFilter = false);

    this.working.selectedSortType = VERTICAL_LIST_SORT_DETAULT_VALUE;
    this.handleApply();
  }

  get waitingOnList() {
    return [
      ...new Set(
        gptModule.wellsWaitingOnStatus.map((i) => i.body || i.summary)
      ),
    ];
  }

  /**
   * This is a helper method that doesn't use `this` context anywhere and placed
   * inside this component as logically it belongs here and is not used globally.
   * We can move it to utils or vuex actions in the future if required in multiple places.
   *
   * Note: Do not use `this` inside it otherwise we wouldn't be able to use it without
   * rendering the whole component. Currently we can access it
   *
   * import VerticalListFiltersAndSort from '@/components/tasqsCommon/VerticalListFiltersAndSort.vue';
   * results = VerticalListFiltersAndSort.options.methods.applyFilteringOnTasqs(tasqs, listLevel, filterSettings);
   */
  applyFilteringOnTasqs(tasqs, listLevel, filter) {
    let results = tasqs;

    if (!filter || Object.keys(filter).length === 0) {
      return results;
    }

    if (listLevel !== 'WorkTicket') {
      if (listLevel === 'Pad') {
        if (filter.selectedWaitingOn && filter.selectedWaitingOn.length) {
          // results = results.filter((t) => filter.selectedWaitingOn.includes(t.waitUntilReason));
        }
      } else if (filter.selectedWaitingOn && filter.selectedWaitingOn.length) {
        results = results.filter((t) => {
          let padWaitingOn = null;
          const wellWaitingOn = gptModule.waitingOnStatusByWellName(t.wellName);
          if (t.padName) {
            padWaitingOn = gptModule.waitingOnStatusByWellName(t.padName);
          }

          const data = this.getRcentWaitingOnStatus({
            wellWaitingOn,
            padWaitingOn,
          });
          return filter.selectedWaitingOn.find((status) => {
            if (status === 'None') {
              return !data;
            }
            return (
              !!data &&
              ((data.body &&
                status.toLowerCase() === data.body.trim().toLowerCase()) ||
                (data.summary &&
                  status.toLowerCase() === data.summary.trim().toLowerCase()))
            );
          });
        });
      }
    }

    if (filter.selectedTeams && filter.selectedTeams.length) {
      if (listLevel === 'Pad') {
        // @ts-ignore
        results = results.filter(
          (t) =>
            !!t.wells.find((well) => filter.selectedTeams.includes(well.team))
        );
      } else {
        // @ts-ignore
        results = results.filter((t) => filter.selectedTeams.includes(t.team));
      }
    }

    if (filter.selectedPads && filter.selectedPads.length) {
      if (listLevel !== 'Pad') {
        // @ts-ignore
        results = results.filter((t) =>
          filter.selectedPads.includes(t.padName)
        );
      }
    }

    if (filter.selectedAreas && filter.selectedAreas.length) {
      // @ts-ignore
      if (listLevel === 'Pad') {
        // @ts-ignore
        results = results.filter(
          (t) =>
            !!t.wells.find((well) => filter.selectedAreas.includes(well.area))
        );
      } else {
        // @ts-ignore
        results = results.filter(
          (t) => t.area && filter.selectedAreas.includes(t.area)
        );
      }
    }

    if (filter.selectedRoutes && filter.selectedRoutes.length) {
      // @ts-ignore
      if (listLevel === 'Pad') {
        // @ts-ignore
        results = results.filter(
          (t) =>
            !!t.wells.find((well) => filter.selectedRoutes.includes(well.route))
        );
      } else {
        // @ts-ignore
        results = results.filter(
          (t) => t.area && filter.selectedRoutes.includes(t.route)
        );
      }
    }

    if (
      this.operator === 'petronas' &&
      filter.downtimeCodes &&
      filter.downtimeCodes.length
    ) {
      results = results.filter((t) => {
        let downtimeCodes = gptModule.wellDowntimeCodes(t.wellName);

        if (filter.downtimeCodes.includes('None')) {
          // If filter.downtimeCodes includes 'None', return results that don't have values in downtimeCodes
          return (
            !downtimeCodes ||
            !downtimeCodes.localVal ||
            downtimeCodes.localVal.length === 0
          );
        }

        if (downtimeCodes) {
          return filter.downtimeCodes.some((code) =>
            downtimeCodes.localVal.includes(code)
          );
        }

        return false;
      });
    }

    if (filter.shutdownAlarms) {
      const alarmsByWellName = workflowModule.wellsAlarms;
      results = results.filter((t) => {
        // let alarmByWellName = workflowModule.alarmByWellName(t.wellName);
        // Find if there's a matching wellName in alarmByWellName with shutdown true
        let matchingAlarm = false;
        if (this.operator === 'pdc') {
          matchingAlarm = alarmsByWellName.find(
            (payload) =>
              payload.node_name === t.wellName && payload.shutdown === true
          );
        } else {
          matchingAlarm = alarmsByWellName.find(
            (payload) =>
              payload.name === t.node_name && payload.shutdown === true
          );
        }

        // Return true if matchingAlarm exists, to keep this item in the results array
        return !!matchingAlarm;
      });
    }

    if (
      (listLevel !== 'Pad' && this.operator !== 'pdc') ||
      this.operator === 'pdc'
    ) {
      if (filter.allAlarms) {
        const alarmsByWellName = workflowModule.wellsAlarms;
        results = results.filter((t) => {
          //  let alarmByWellName = workflowModule.alarmByWellName(t.wellName);
          // Find if there's a matching wellName in alarmByWellName with shutdown true
          let matchingAlarm = alarmsByWellName.find(
            (payload) =>
              payload.node_name === t.wellName && payload.alarm === true
          );

          // Return true if matchingAlarm exists, to keep this item in the results array
          return !!matchingAlarm;
        });
      }
    }

    if (
      filter.typesOptions &&
      filter.typesOptions.length &&
      (filter.typesOptions.includes('TO-DO FOR YOU') ||
        filter.typesOptions.includes('TO-DO LIST'))
    ) {
      const nodesTodoStatus = gptModule.wellsAssigneeStatus;
      results = results.filter(
        (t) =>
          (filter.typesOptions.includes('TO-DO FOR YOU') &&
            nodesTodoStatus.find(
              (payload) =>
                payload.wellName === t.wellName &&
                payload.activeTodolistForUser === true
            )) ||
          (filter.typesOptions.includes('TO-DO LIST') &&
            nodesTodoStatus.find(
              (payload) =>
                payload.wellName === t.wellName && payload.totalActiveItemsCount
            ))
      );
    }

    if (filter.selectedUsernames) {
      const nodesTodoStatus = gptModule.wellsAssigneeStatus;
      const usernames = filter.selectedUsernames.map((u) => u.value);
      if (usernames.length) {
        results = results.filter((result) => {
          // Direct username check
          if (usernames.includes(result.username)) {
            return true;
          }

          return nodesTodoStatus.some((node) => {
            if (node.wellName === result.wellName) {
              // Iterate over the assignedTodoList array to check if any of the usernames match
              return node.assignedTodoList.some(([assignedUsername]) => {
                // Only compare the username part of the array (ignore the numeric value)
                return usernames.includes(assignedUsername);
              });
            }
            return false;
          });
        });
      }
    }

    if (listLevel !== 'Pad' && listLevel !== 'WorkTicket') {
      if (filter.typesOptions && filter.typesOptions.length) {
        // const arrayIntersection = (arr1, arr2) => arr1.some(item => arr2.includes(item));
        let typesOptions = filter.typesOptions;
        typesOptions = typesOptions.filter(
          (o) => o != 'TO-DO FOR YOU' && o != 'TO-DO LIST'
        );
        if (typesOptions.length) {
          results = results.filter(
            (t) =>
              typesOptions.includes(t.predictionType) ||
              typesOptions.includes(`${t.predictionType} ${t.highLP}`) ||
              typesOptions.some(
                (item) => t.predictionTypes && t.predictionTypes.includes(item)
              )
          );
        }
      }

      if (filter.unitsGreaterThanFilter) {
        results = results.filter((t) => {
          let defermentObj = gptModule.wellDefermentDetails(t.wellName);

          if (
            defermentObj &&
            defermentObj.Deferment &&
            t.predictionType !== 'Producing'
          ) {
            return (
              defermentObj.Deferment &&
              defermentObj.Deferment >= filter.unitsGreaterThanFilter
            );
          }
          return false;
        });
      }

      if (filter.unitsLessThanFilter) {
        results = results.filter((t) => {
          let defermentObj = gptModule.wellDefermentDetails(t.wellName);
          if (
            defermentObj &&
            defermentObj.Deferment &&
            t.predictionType !== 'Producing'
          ) {
            return (
              defermentObj.Deferment &&
              defermentObj.Deferment <= filter.unitsLessThanFilter
            );
          }
          return false;
        });
      }

      if (filter.defermentPercentGreaterThanFilter) {
        results = results.filter((t) => {
          let defermentObj = gptModule.wellDefermentDetails(t.wellName);
          if (
            defermentObj &&
            defermentObj.Deferment_Percentage &&
            t.predictionType !== 'Producing'
          ) {
            let defermentPercentage = Math.round(
              Math.abs(defermentObj.Deferment_Percentage)
            );
            defermentPercentage =
              defermentPercentage > 100 ? 100 : defermentPercentage;
            return (
              defermentPercentage >= filter.defermentPercentGreaterThanFilter
            );
          }
          return false;
        });
      }

      if (filter.defermentPercentLessThanFilter) {
        console.log(filter.defermentPercentGreaterThanFilter);

        results = results.filter((t) => {
          let defermentObj = gptModule.wellDefermentDetails(t.wellName);
          if (
            defermentObj &&
            defermentObj.Deferment_Percentage &&
            t.predictionType !== 'Producing'
          ) {
            let defermentPercentage = Math.round(
              Math.abs(defermentObj.Deferment_Percentage)
            );
            defermentPercentage =
              defermentPercentage > 100 ? 100 : defermentPercentage;
            return defermentPercentage <= filter.defermentPercentLessThanFilter;
          }
          return false;
        });
      }

      if (filter.cycleTimeGreaterThanFilter) {
        results = results.filter(
          (t) =>
            t.deferringDays &&
            t.deferringDays >= filter.cycleTimeGreaterThanFilter
        );
      }

      if (filter.cycleTimeLessThanFilter) {
        results = results.filter(
          (t) =>
            t.deferringDays && t.deferringDays <= filter.cycleTimeLessThanFilter
        );
      }
    }

    if (listLevel === 'WorkTicket') {
      if (
        filter.selectedWorkTicketCategories &&
        filter.selectedWorkTicketCategories.length
      ) {
        results = results.filter(
          (t) =>
            t.jobCategory &&
            filter.selectedWorkTicketCategories.includes(t.jobCategory)
        );
      }

      if (
        filter.selectedWorkTicketTypes &&
        filter.selectedWorkTicketTypes.length
      ) {
        results = results.filter(
          (t) => t.jobType && filter.selectedWorkTicketTypes.includes(t.jobType)
        );
      }

      if (
        filter.selectedWorkTicketStatus &&
        filter.selectedWorkTicketStatus.length
      ) {
        results = results.filter(
          (t) => t.status && filter.selectedWorkTicketStatus.includes(t.status)
        );
      }

      if (
        filter.selectedWorkTicketPriorities &&
        filter.selectedWorkTicketPriorities.length
      ) {
        results = results.filter(
          (t) =>
            t.priority &&
            filter.selectedWorkTicketPriorities.includes(t.priority)
        );
      }

      if (filter.selectedUsernames) {
        const usernames = filter.selectedUsernames.map((u) => u.value);
        if (usernames.length) {
          results = results.filter((t) => usernames.includes(t.username));
        }
      }
    }

    return results;
  }

  updateValues(array1, array2, key1, key2, valueKeys) {
    // Create a map from array2 for quick lookup
    const map = new Map(array2.map((item) => [item[key2], item]));

    // Create a new array and update the values
    return array1.map((item) => {
      const newItem = { ...item };

      if (map.has(newItem[key1])) {
        if (newItem.predictionType !== 'Producing') {
          const matchedItem = map.get(newItem[key1]);

          valueKeys.forEach(([valueKey1, valueKey2]) => {
            if (matchedItem[valueKey2] === undefined) {
              newItem[valueKey1] = 0;
            } else if (matchedItem[valueKey2] !== undefined) {
              newItem[valueKey1] = matchedItem[valueKey2]
                ? Math.abs(matchedItem[valueKey2])
                : 0;
            }
          });
        }
      }

      if(!newItem.defermentValue) {
        newItem.defermentValue = 0;
      }
      if(!newItem.defermentPercentage) {
        newItem.defermentPercentage = 0;
      }

      return newItem;
    });
  }

  /**
   * This is a helper method that doesn't use `this` context anywhere and placed
   * inside this component as logically it belongs here and is not used globally.
   * We can move it to utils or vuex actions in the future if required in multiple places.
   *
   * Note: Do not use `this` inside it otherwise we wouldn't be able to use it without
   * rendering the whole component. Currently we can access it
   *
   * import VerticalListFiltersAndSort from '@/components/tasqsCommon/VerticalListFiltersAndSort.vue';
   * results = VerticalListFiltersAndSort.options.methods.sortTasqs(tasqs, listLevel, sortBy);
   */
  sortTasqs(tasqs, listLevel, sortBy = VERTICAL_LIST_SORT_DETAULT_VALUE) {
    let results = JSON.parse(JSON.stringify(tasqs));
    if (
      gptModule.wellDefermentDetails.length > 0 &&
      sortBy.includes('deferment')
    ) {
      results = this.updateValues(
        tasqs,
        gptModule.wellDefermentPercentage,
        'wellName',
        'NodeID',
        [
          ['defermentValue', 'Deferment'],
          ['defermentPercentage', 'Deferment_Percentage'],
        ]
      );
    }

    let sortKey: any = 'defermentValue';
    if (sortBy === 'deferment-desc') {
      sortKey = 'defermentValue';
      if (listLevel === 'Pad') {
        sortKey = 'padDeferment';
      }
      return sort(results).desc(sortKey);
    }

    if (sortBy === 'deferment-asc') {
      sortKey = 'defermentValue';
      if (listLevel === 'Pad') {
        sortKey = 'padDeferment';
      }
      return sort(results).asc(sortKey);
    }

    if (sortBy === 'cycleTime-asc') {
      sortKey = 'dateAgoValue';
      return sort(results).asc(sortKey);
    }

    if (sortBy === 'cycleTime-desc') {
      sortKey = 'dateAgoValue';
      return sort(results).desc(sortKey);
    }

    if (sortBy === 'defermentPercent-asc') {
      sortKey = 'defermentPercentage';
      return sort(results).asc(sortKey);
    }

    if (sortBy === 'defermentPercent-desc') {
      sortKey = 'defermentPercentage';
      return sort(results).desc(sortKey);
    }

    if (sortBy === 'wellName-asc') {
      if (
        listLevel === 'Well' ||
        listLevel === 'WellView' ||
        listLevel === 'WorkTicket'
      ) {
        sortKey = 'wellName';
        return sort(results).asc(sortKey);
      }
      sortKey = 'padName';
      return sort(results).asc(sortKey);
    }

    if (sortBy === 'wellName-desc') {
      if (
        listLevel === 'Well' ||
        listLevel === 'WellView' ||
        listLevel === 'WorkTicket'
      ) {
        sortKey = 'wellName';
        return sort(results).desc(sortKey);
      }
      sortKey = 'padName';
      return sort(results).desc(sortKey);
    }

    if (sortBy === 'actioned-desc') {
      sortKey = 'lastActionTime';
      return sort(results).desc(sortKey);
    }

    if (sortBy === 'actioned-asc') {
      sortKey = 'lastActionTime';
      return sort(results).asc(sortKey);
    }

    if (sortBy === 'priority-desc') {
      sortKey = 'priorityLevel';
      return sort(results).desc(sortKey);
    }

    if (sortBy === 'priority-asc') {
      sortKey = 'priorityLevel';
      return sort(results).asc(sortKey);
    }

    return results;
  }

  setFilteringValuesInStore() {
    this.working.selectedUsernames = this.working.selectedUsernames.filter(
      (u) => u && u.value
    );
    tasqsListModule.setActiveFilter({
      id: this.workingFilterId,
      name: this.workingFilterName,
      settings: this.working,
    });
  }

  cancel() {
    this.$emit('show-filters-popup-updated', false);
  }

  initFilteringValues() {
    this.working = JSON.parse(JSON.stringify(this.filteringDetails));
  }

  getDistinctNames(padDetails, filterLists) {
    const { routes, teams, areas, pads } = filterLists;

    const filteredPadDetails = padDetails.filter(
      (detail) =>
        (teams.length === 0 || teams.includes(detail.Team)) &&
        (areas.length === 0 || areas.includes(detail.Area)) &&
        (routes.length === 0 || routes.includes(detail.Route)) &&
        (pads.length === 0 || pads.includes(detail.PadName))
    );

    const distinctNames = {
      teams: [
        ...new Set(
          filteredPadDetails
            .map((detail) => detail.Team)
            .filter((name) => name !== null)
            .sort()
        ),
      ],
      areas: [
        ...new Set(
          filteredPadDetails
            .map((detail) => detail.Area)
            .filter((name) => name !== null)
            .sort()
        ),
      ],
      routes: [
        ...new Set(
          filteredPadDetails
            .map((detail) => detail.Route)
            .filter((name) => name !== null)
            .sort()
        ),
      ],
      pads: [
        ...new Set(
          filteredPadDetails
            .map((detail) => detail.PadName)
            .filter((name) => name !== null)
            .sort()
        ),
      ],
    };

    return distinctNames;
  }

  get isLoadingPaginatedTasqs() {
    return tasqsListModule.isLoadingPaginatedTasqs;
  }

  get isDataLoading() {
    return this.isLoadingTasqs || this.isLoadingPaginatedTasqs;
  }

  @Debounce(2000)
  @Watch('isLoadingPaginatedTasqs')
  async onTasqRouteIdChange(value: any) {
    if (!value) {
      this.handleApply();
      const records = this.applyFilteringOnTasqs(
        this.getAllTasqs(
          this.working.selectedRoutes,
          this.working.selectedAreas,
          this.working.selectedPads
        ),
        this.tasqListLevel,
        this.working
      );
      this.showingTotalResultsValue = records.length;
    }
  }

  @Debounce(2500)
  @Watch('working', { immediate: true, deep: true })
  updateShowingTotalResultsValue() {
    const records = this.applyFilteringOnTasqs(
      this.getAllTasqs(
        this.working.selectedRoutes,
        this.working.selectedAreas,
        this.working.selectedPads
      ),
      this.tasqListLevel,
      this.working
    );
    this.showingTotalResultsValue = records.length;
  }

  @Watch('filteringDetails', {
    immediate: true,
  })
  onFilteringDetailsChange(value, oldValue) {
    this.initFilteringValues();
  }

  @Watch('showFiltersPopup')
  onShowFiltersPopupChange(value, oldValue) {
    if (value === true) {
      this.loading = false;
      this.workingFilterId = this.filterId;
      this.workingFilterName = this.filterName;
      this.saveAsCustomFilter = false;
      if (this.workingFilterId) {
        this.saveAsCustomFilter = true;
      }
      this.initFilteringValues();
    }
  }
}
